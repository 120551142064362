import {
  ApiResponse,
  Group,
  Visitor,
  Pagination,
  VisitorPageModel,
  ErrorProp,
} from '@models';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

const visitorActions = createActionGroup({
  source: 'Visitor page',
  events: {
    'fetch visitor': emptyProps(),
    'fetch visitor success': props<{ data: ApiResponse<Visitor[]> }>(),
    'assign visitors': props<{
      data: { ids: Visitor['id'][]; groupId: Group['id'] };
    }>(),
    'update filter': props<{ data: VisitorPageModel.filter }>(),
    'update Pagination': props<{ data: Pagination }>(),
    'update visitor': props<{
      clientDetails: Partial<Visitor['clientDetails']>;
      id: Visitor['id'];
    }>(),

    'update visitor success': props<{ data: Visitor }>(),
    'handle failed': props<{ error: ErrorProp }>(),
    'fetch analytics': props<{
      id: string;
    }>(),
    'fetch analytics success': props<{
      analytic: VisitorPageModel.Analytics;
      id: Visitor['id'];
    }>(),
    'fetch analytics loading': emptyProps(),
    'fetch analytics failure': props<{ error: any }>(),
  },
});
export default visitorActions;
