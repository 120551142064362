import { createFeatureSelector, createSelector } from '@ngrx/store';
import { VisitorState } from './visitor.state';
import { Visitor } from '@models';

export const VISITOR_STATE_NAME = 'visitor';
const selectVisitorState =
  createFeatureSelector<VisitorState>(VISITOR_STATE_NAME);
export const selectVisitorCount = createSelector(
  selectVisitorState,
  state => state.total
);
const selectVisitors = createSelector(selectVisitorState, ({ data }) => data);
const selectFilter = createSelector(selectVisitorState, ({ filter }) => filter);
const selectPagination = createSelector(
  selectVisitorState,
  ({ pagination }) => pagination
);
const selectAnalyticsByVisitorId = (id: Visitor['id']) =>
  createSelector(selectVisitorState, ({ analytics }) => {
    return analytics[id] ?? undefined;
  });
const selectPaginationAndFilter = createSelector(
  selectVisitorState,
  ({ pagination, filter }) => ({ filter, pagination })
);

const selectTotal = createSelector(selectVisitorState, ({ total }) => total);

const selectVisitorIsLoading = createSelector(
  selectVisitorState,
  ({ loading }) => loading
);

export default {
  selectAnalyticsByVisitorId,
  selectFilter,
  selectVisitors,
  selectTotal,
  selectPagination,
  selectPaginationAndFilter,
  selectVisitorIsLoading,
};
